import { Circle, SquareProps } from '@chakra-ui/react';
import { useCartCount } from '../hooks/useShoppingCartStore.tsx';

export const CartCount = (props: SquareProps) => {
  const totalItems = useCartCount();

  if (totalItems === 0) return null;

  return (
    <Circle
      fontSize='xs'
      fontWeight='semibold'
      position='absolute'
      top='-2'
      right='-4'
      bg={'blue.500'}
      color={'white'}
      width='5'
      height='5'
      {...props}>
      {totalItems}
    </Circle>
  );
};
