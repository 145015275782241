import { Box, Flex, HStack, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@cksoftware/react-base';
import logo from '../../../assets/logo.png';
import { AppRoles } from '../../../constants/appRoles.ts';
import { items } from './NavItemIcons.tsx';
import { AiOutlineUser } from 'react-icons/ai';
import { ApplicationRoutes } from '../../../constants/appRoutes.ts';
import { DesktopNavAction } from './NavAction.tsx';
import { CartCount } from './CartCount.tsx';
import { SearchInput } from './SearchInput.tsx';

type Props = {
  setSearchValue: (value: string) => void;
  onCartClick: () => void;
  onRequestClick: () => void;
};

export const CustomerNavDesktop = (props: Props) => {
  const nav = useNavigate();
  const auth = useAuth();
  return (
    <Box px='8' bg={'white'}>
      <Flex height='4.5rem' align='center' maxW='8xl' mx='auto'>
        <Box flexShrink={0}>
          <Image height={'10'} fill='currentColor' src={logo} alt='logo' />
        </Box>
        <Box width='full' mx='8'>
          <SearchInput setSearchValue={props.setSearchValue} />
        </Box>
        <HStack gap='8' flexShrink={0}>
          {auth.isInRole([AppRoles.ProductAdmin, AppRoles.Admin]) && (
            <DesktopNavAction {...items.admin} onClick={props.onRequestClick} />
          )}
          <DesktopNavAction {...items.request} onClick={props.onRequestClick} />

          <Box position='relative'>
            <DesktopNavAction {...items.cart} onClick={props.onCartClick} />
            <CartCount />
          </Box>

          <DesktopNavAction
            label='Sign out'
            icon={<AiOutlineUser />}
            onClick={() => nav(ApplicationRoutes.account.logout)}
          />
        </HStack>
      </Flex>
    </Box>
  );
};
