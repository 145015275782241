import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { ProductImage } from '../models/ProductImage.ts';
import { ProductOptionModel } from '../productView/models/ProductListingModel.ts';

export type ProductMetaProps = {
  name: string;
  forms: string[];
  usages: string[];
  image: ProductImage;
  options: ProductOptionModel[];
};

export const ProductMeta = (props: ProductMetaProps) => {
  const { image, name, forms, options, usages } = props;
  return (
    <Stack direction='row' gap={{ base: '3', md: '5' }} width='full'>
      {image && (
        <Box width='24' height='24' rounded='lg' borderWidth='1px' overflow='hidden'>
          <Image
            fit='cover'
            src={image.Url}
            alt={image.AltText}
            draggable='false'
            loading='lazy'
            width='full'
            height='full'
          />
        </Box>
      )}

      <Box pt='2'>
        <Stack fontSize='sm' align='flex-start'>
          <Text fontWeight='semibold' lineHeight='1' truncate>
            {name}
          </Text>
          <Text color={'gray.600'} lineHeight='1'>
            {[
              ...new Set(options.flatMap((item) => item.ProductPacks).map((r) => `${r.Strength} ${r.StrengthUnit}`))
            ].join(', ')}
          </Text>
          {forms && (
            <Text color={'gray.600'} lineHeight='1'>
              {[...new Set(forms.map((item) => item))].join(', ')}
            </Text>
          )}
          {usages && (
            <Text color={'gray.600'} lineHeight='1'>
              {[...new Set(usages.map((item) => item))].join(', ')}
            </Text>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
