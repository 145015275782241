import { useCallback, useState } from 'react';
import { ProductFilterState } from './useProductListing.tsx';

export function useFilterState() {
  const [filterState, setProductFilter] = useState<ProductFilterState>({});

  const setProductFormId = useCallback((newValue: number[]) => {
    setProductFilter((prevFilter) => ({
      ...prevFilter,
      formIds: !!newValue.length ? newValue.map((item) => Number(item)) : undefined
    }));
  }, []);

  const setSearchString = (val: string) => {
    setProductFilter((prevFilter) => ({
      ...prevFilter,
      keyword: val
    }));
  };

  return {
    filterState,
    setKeyword: setSearchString,
    setFormIds: setProductFormId
  };
}
