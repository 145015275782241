import { createSystem, defaultConfig, mergeConfigs } from '@chakra-ui/react';
import { buildBaseTheme } from '@cksoftware/react-base';

const baseTheme = buildBaseTheme({
  primaryColor: '#2196f3',
  secondaryColor: '#d95b09',
  tertiaryColor: '#64154a',
  buttons: {
    borderRadius: 8
  }
});

const merged = mergeConfigs(defaultConfig, baseTheme);
export default createSystem(merged);
