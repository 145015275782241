import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../../utils/itemStoreMethods.tsx';
import { OrderItem } from '../../itemTypes.ts';

export const useShoppingCartItemStore = create(
  persist<ItemStore<OrderItem>>((set) => itemStoreMethods<OrderItem>(set), {
    name: 'shopping-cart-state'
  })
);
