import { CreateFieldHelperReturn, useGenericCrudGetQuery } from '@cksoftware/react-base';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';
import { CustomOrderItem } from '../../itemTypes.ts';
import { DeliveryType, PatientType } from '../../checkout/types.ts';
import { masks } from '../../../../constants/masks.ts';
import { Box } from '@chakra-ui/react';

export const useCustomProductRequestFormFields = (
  formMethods: UseFormReturn<Partial<CustomOrderItem>>,
  fieldHelper: CreateFieldHelperReturn<Partial<CustomOrderItem>>
) => {
  const strengthDenominatorUnitId = useWatch({
    control: formMethods.control,
    name: 'StrengthDenominatorUnitId'
  });

  const patientType = useWatch({
    control: formMethods.control,
    name: 'PatientType'
  });

  const deliveryType = useWatch({
    control: formMethods.control,
    name: 'DeliveryType'
  });

  const strengthDenominatorUnit = useGenericCrudGetQuery<{ Name: string; Id: number }>(
    strengthDenominatorUnitId ? +strengthDenominatorUnitId : undefined,
    ControllerRoutes.Unit.Base
  );

  return useMemo(() => {
    return [
      fieldHelper
        .selectInputQueryItems('DrugId', { searchUrl: ControllerRoutes.Drug.Select })
        .withLabel('Drug')

        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('FormId', { searchUrl: ControllerRoutes.ProductForm.Select })
        .withLabel('Form')

        .withValidation({ required: {} }),
      fieldHelper.numericInput('Strength').withLabel('Strength').withValidation({ required: {} }),

      fieldHelper
        .selectInputQueryItems('StrengthUnitId', { searchUrl: ControllerRoutes.Unit.Select })
        .withLabel('Unit')
        .withValidation({ required: {} }),

      fieldHelper
        .selectInputQueryItems('StrengthDenominatorUnitId', { searchUrl: ControllerRoutes.Unit.Select })
        .withLabel('Denominator'),

      fieldHelper
        .numericInput('PackSize')
        .withLabel('Package Size' + (strengthDenominatorUnit.data ? ` (${strengthDenominatorUnit.data?.Name})` : ''))
        .withValidation({ required: {} }),

      fieldHelper.selectInputQueryItems('FlavorId', { searchUrl: ControllerRoutes.Flavor.Select }).withLabel('Flavor'),

      fieldHelper.numericInput('Refills').withValidation({ required: {} }),

      fieldHelper.textArea('InstructionsForUse').withValidation({ required: {} }),

      fieldHelper
        .selectInputQueryItems('AuthorizedUserId', { searchUrl: ControllerRoutes.Util.selectOption.authorizedVets })
        .withLabel('Authorized Vet')
        .isString()
        .withValidation({ required: {} }),

      fieldHelper
        .selectInputQueryItems('PatientType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('PatientType')
        })
        .withValidation({ required: {} }),

      fieldHelper
        .textInput('PetName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerPhoneNumber')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withInputMask(masks.phone)
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('DeliveryType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('DeliveryType')
        })
        .withRenderUnder(
          deliveryType == DeliveryType.DeliverToClinic ? (
            <Box fontSize={'xs'} marginTop={'-5px'} fontStyle={'italic'}>
              Delivery will be sent to address on file
            </Box>
          ) : (
            <></>
          )
        )
        .withValidation({ required: {} }),
      fieldHelper
        .addressInput('DeliveryAddress.AddressDisplay', {
          countryField: 'DeliveryAddress.Country',
          postalCodeField: 'DeliveryAddress.PostalCode',
          streetField: 'DeliveryAddress.AddressLineOne',
          cityField: 'DeliveryAddress.City',
          provinceField: 'DeliveryAddress.Province'
        })
        .withLabel('Patient Address')
        .withRenderIf(() => {
          return deliveryType == DeliveryType.DeliverToPatient;
        }),
      fieldHelper.textArea('DeliveryComments'),

      fieldHelper.textArea('ItemComment').withLabel('Order Note')
    ];
  }, [deliveryType, fieldHelper, patientType, strengthDenominatorUnit.data]);
};
