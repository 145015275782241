import { Box, Flex, HStack, Stack, UseDisclosureReturn } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useShoppingCartData } from './hooks/useShoppingCartData.tsx';
import { BaseButton, BaseDrawer } from '@cksoftware/react-base';
import { CartItem } from './components/CartItem.tsx';
import { PriceTag } from '../../../components/common/PriceTag.tsx';

type ShoppingCartProps = {
  disclosure: UseDisclosureReturn;
  onCheckout: () => void;
};

export const ShoppingCart = (props: ShoppingCartProps) => {
  const { combinedItems, removeItem, setQuantity } = useShoppingCartData();
  const sum = useMemo(() => {
    let total = 0;
    combinedItems.forEach((item) => {
      if (item.Price && item.Quantity) {
        total += item.Price * item.Quantity;
      }
    });

    return total;
  }, [combinedItems]);

  return (
    <Box height='100vh'>
      <BaseDrawer
        trigger={<></>}
        disclosure={props.disclosure}
        footer={
          <Flex flexDir={'column'} w={'100%'}>
            <Stack borderTopWidth='1px' px={{ base: '4', md: '6' }} py='4' gap='5'>
              <HStack w={'100%'} fontSize='xl' fontWeight='semibold' justifyContent={'flex-end'}>
                <Box>Subtotal:</Box>
                <PriceTag minimumPrice={sum} />
              </HStack>
            </Stack>
            <Flex gap={'4px'}>
              <BaseButton
                onClick={() => {
                  props.disclosure.onClose();
                  props.onCheckout();
                }}>
                Checkout
              </BaseButton>
              <BaseButton variant={'outline'} onClick={props.disclosure.onClose}>
                Close
              </BaseButton>
            </Flex>
          </Flex>
        }
        size='md'
        header={'Shopping Cart' + ` (${combinedItems.length} items)`}>
        <Stack height='full' gap='8' overflowY='auto'>
          <Stack gap={{ base: '4', md: '6' }}>
            {combinedItems.map((item, key) => {
              return (
                <CartItem
                  key={key}
                  onChangeQuantity={(quantity: number) => {
                    setQuantity(item.StorageId, quantity);
                  }}
                  onClickDelete={() => {
                    removeItem(item.StorageId);
                  }}
                  model={item}
                />
              );
            })}
          </Stack>
        </Stack>
      </BaseDrawer>
    </Box>
  );
};
