import { Box, Flex, Stack, Text, UseDisclosureReturn } from '@chakra-ui/react';
import {
  BaseButton,
  BaseFormDialog,
  FlexFormFields,
  FormComponents,
  useGenericCrudGetQuery,
  useHookForm
} from '@cksoftware/react-base';
import { ControllerRoutes } from '../../../constants/controllerRoutes.ts';
import { ProductListingModel } from './models/ProductListingModel.ts';
import { OrderItem } from '../itemTypes.ts';
import { useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useProductViewFormFields } from './util/useProductViewFormFields.tsx';
import { useShoppingCartItemStore } from '../shoppingCart/hooks/useShoppingCartStateStore.tsx';
import { QuantityPicker } from '../shoppingCart/components/QuantityPicker.tsx';
import { PriceTag } from '../../../components/common/PriceTag.tsx';

type ProductViewProps = {
  productId: number;
  disclosure: UseDisclosureReturn;
};

export const ProductViewModal = (props: ProductViewProps) => {
  const { disclosure, productId } = props;
  const productQuery = useGenericCrudGetQuery<ProductListingModel>(productId, ControllerRoutes.ProductListing.Base);

  const { formMethods } = useHookForm<Partial<OrderItem>>({
    ProductStrengthId: 0,
    FlavorId: undefined!,
    ItemComment: '',
    ProductOptionId: undefined!,
    ProductPackId: undefined!,
    Quantity: 1,
    DrugId: productQuery.data?.Id
  });

  const productOptionId = useWatch({
    control: formMethods.control,
    name: 'ProductOptionId'
  });

  const productPackId = useWatch({
    control: formMethods.control,
    name: 'ProductPackId'
  });

  const productStrengthId = useWatch({
    control: formMethods.control,
    name: 'ProductStrengthId'
  });

  const quantity = useWatch({
    control: formMethods.control,
    name: 'Quantity'
  });

  useEffect(() => {
    formMethods.setValue('ProductStrengthId', undefined);
    formMethods.setValue('ProductPackId', undefined);
    formMethods.setValue('FlavorId', undefined);
  }, [formMethods, productOptionId]);

  const fields = useProductViewFormFields(formMethods, productQuery.data, productOptionId);

  const productPrice = useMemo(() => {
    if (!productPackId || !productQuery.data) {
      return undefined;
    }

    const matchingPack = productQuery.data.ProductOptions.flatMap((val) => val.ProductPacks).find(
      (val) => val.ProductStrengthId === productStrengthId && val.ProductPackId === productPackId
    );

    if (!matchingPack) {
      return undefined;
    }
    return matchingPack.Price * (quantity ?? 0);
  }, [productPackId, productQuery.data, quantity, productStrengthId]);

  const productStore = useShoppingCartItemStore();

  if (!productQuery.data) {
    return <></>;
  }

  return (
    <BaseFormDialog
      isLoading={productQuery.isLoading}
      formControlProps={{
        isSubmitting: false,
        submitButtonText: 'Add to cart',
        submitButtonProps: { style: { width: '100%' } }
      }}
      disclosure={disclosure}
      size={'md'}
      header={productQuery.data?.DrugName}
      formMethods={formMethods}
      footerRender={
        <Flex justifyContent={'space-between'} w={'100%'}>
          <Stack direction={{ base: 'column', md: 'row' }} gap='1' align='baseline' justify='space-between'>
            {productPrice && (
              <>
                <Box fontWeight={'bold'}>Total Cost:</Box>
                <PriceTag minimumPrice={productPrice} rootProps={{ fontSize: 'xl' }} />
              </>
            )}
          </Stack>

          <BaseButton type={'submit'}>Add To Cart</BaseButton>
        </Flex>
      }
      onSubmit={async (model: Partial<OrderItem>) => {
        productStore.addItem(model as OrderItem);
        disclosure.onClose();
      }}>
      {productQuery.data && (
        <>
          {productQuery.data.DrugDescription && (
            <Text color={'gray.600'} dangerouslySetInnerHTML={{ __html: productQuery.data.DrugDescription }}></Text>
          )}
          <FlexFormFields formFields={fields}></FlexFormFields>
          <FormComponents.FormFieldContainer invalid={false} label={'Quantity'}>
            <QuantityPicker
              max={99}
              min={1}
              defaultValue={1}
              value={formMethods.getValues('Quantity')}
              onChange={(value) =>
                formMethods.setValue('Quantity', value, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true
                })
              }
            />
          </FormComponents.FormFieldContainer>
        </>
      )}
    </BaseFormDialog>
  );
};
