import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeProvider, type ColorModeProviderProps } from './color-mode';
import ChakraTheme from '../../theme/chakraTheme.tsx';

export function ChakraRootProvider(props: ColorModeProviderProps) {
  return (
    <ChakraProvider value={ChakraTheme}>
      <ColorModeProvider {...props} forcedTheme={'light'} />
    </ChakraProvider>
  );
}
