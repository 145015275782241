import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../utils/itemStoreMethods.tsx';
import { CustomOrderItem } from '../itemTypes.ts';

export const useCustomRequestStateStore = create(
  persist<ItemStore<CustomOrderItem>>((set) => itemStoreMethods<CustomOrderItem>(set), {
    name: 'custom-request-state'
  })
);
