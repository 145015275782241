import { BaseButton, BaseFormDialog, useHookForm, useStandardToast } from '@cksoftware/react-base';
import { Box, Flex, HStack, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { useShoppingCartData } from '../shoppingCart/hooks/useShoppingCartData.tsx';
import { CheckoutFormModel } from './types.ts';
import { useMemo } from 'react';
import { usePlaceOrderMutation } from './api/usePlaceOrderMutation.ts';
import { PriceTag } from '../../../components/common/PriceTag.tsx';
import { CartItem, CartItemProps } from '../shoppingCart/components/CartItem.tsx';

type Props = {
  disclosure: UseDisclosureReturn;
};

export const CheckoutModal = (props: Props) => {
  const { formMethods } = useHookForm<CheckoutFormModel>({
    AuthorizedUserId: undefined!,
    DeliveryAddress: undefined,
    DeliveryComments: undefined,
    DeliveryType: undefined!,
    InstructionsForUse: '',
    OwnerName: undefined,
    OwnerPhoneNumber: undefined,
    PatientType: undefined!,
    PetName: undefined,
    Refills: 0
  });
  const { combinedItems, productItems, customItems, setQuantity, removeItem, clear } = useShoppingCartData();

  const sum = useMemo(() => {
    let total = 0;
    combinedItems.forEach((item) => {
      if (item.Price && item.Quantity) {
        total += item.Price * item.Quantity;
      }
    });

    return total;
  }, [combinedItems]);

  const placeOrderMutation = usePlaceOrderMutation();
  const toast = useStandardToast();
  const onSubmit = async (data: CheckoutFormModel) => {
    await placeOrderMutation.mutateAsync({
      ...data,
      Products: productItems.map((r) => r.data),
      CustomRequests: customItems.map((r) => r.data)
    });
    toast.successToast('Order Placed', 'Thank you, your order has been submitted successfully.');
    clear();
    formMethods.reset();
    props.disclosure.onClose();
  };

  return (
    <BaseFormDialog<CheckoutFormModel>
      isLoading={placeOrderMutation.isPending}
      disclosure={props.disclosure}
      formMethods={formMethods}
      closeOnEsc={false}
      disableCloseOnClick={true}
      size={'md'}
      header={`Shopping Cart (${combinedItems.length} items)`}
      footerRender={
        <Flex w={'100%'} justifyContent={'space-between'}>
          <HStack fontSize='xl' fontWeight='semibold'>
            <Text flex='1'>Subtotal:</Text>
            <PriceTag minimumPrice={sum} />
          </HStack>

          <BaseButton type={'submit'}>Place Order</BaseButton>
        </Flex>
      }
      formControlProps={{
        submitButtonText: 'Place Order',
        isSubmitting: placeOrderMutation.isPending
      }}
      onSubmit={onSubmit}>
      <Box>
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box flex='1'>
            <Flex flexDir={'column'} gap='10px'>
              {combinedItems.map((item, key) => {
                const cartItem: CartItemProps = {
                  model: item,
                  onChangeQuantity: (quantity) => setQuantity(item.StorageId, quantity),
                  onClickDelete: () => removeItem(item.StorageId)
                };
                return <CartItem key={key} {...cartItem} />;
              })}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </BaseFormDialog>
  );
};
