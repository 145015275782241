import { BaseFormDialog, FormControls, useHookForm } from '@cksoftware/react-base';
import { UseDisclosureReturn } from '@chakra-ui/react';
import { CustomProductRequestForm } from './components/customProductRequestForm.tsx';
import { useCustomRequestStateStore } from '../hooks/useCustomRequestStore.tsx';
import { getAxios } from '../../../util/axios.ts';
import { CustomOrderItem } from '../itemTypes.ts';

type Props = {
  disclosure: UseDisclosureReturn;
};

export const CustomProductRequestModal = (props: Props) => {
  const { disclosure } = props;

  const { formMethods } = useHookForm<Partial<CustomOrderItem>>({
    Quantity: 1
  });

  const requestStore = useCustomRequestStateStore();

  const onSubmit = async (data: Partial<CustomOrderItem>) => {
    //total hack for now
    const productInformation = await getAxios().post<{
      DrugName: string;
      FormName: string;
      UsageName: string;
      StrengthUnitName: string;
      StrengthDenominatorUnitName: string;
      FlavorName: string;
    }>('/order/CustomRequestInformation', {
      DrugId: data.DrugId,
      FormId: data.FormId,
      StrengthUnitId: data.StrengthUnitId,
      StrengthDenominatorUnitId: data.StrengthDenominatorUnitId,
      FlavorId: data.FlavorId
    });

    const newItem: CustomOrderItem = {
      AuthorizedUserId: data.AuthorizedUserId!,
      DeliveryAddress: data.DeliveryAddress!,
      DeliveryComments: data.DeliveryComments!,
      DeliveryType: data.DeliveryType!,
      DrugId: data.DrugId!,
      DrugName: productInformation.data.DrugName!,
      Flavor: productInformation.data.FlavorName,
      FlavorId: data.FlavorId!,
      FormId: data.FormId!,
      FormName: productInformation.data.FormName,
      InstructionsForUse: data.InstructionsForUse!,
      ItemComment: data.ItemComment!,
      OwnerName: data.OwnerName,
      OwnerPhoneNumber: data.OwnerPhoneNumber,
      PackSize: data.PackSize!,
      PatientType: data.PatientType!,
      PetName: data.PetName,
      Quantity: data.Quantity!,
      Refills: data.Refills!,
      Strength: data.Strength!,
      StrengthDenominator: productInformation.data.StrengthDenominatorUnitName,
      StrengthUnit: productInformation.data.StrengthUnitName,
      StrengthUnitId: data.StrengthUnitId!,
      StrengthDenominatorUnitId: data.StrengthDenominatorUnitId!
    };

    requestStore.addItem(newItem);

    formMethods.reset();
    disclosure.onClose();
  };

  return (
    <BaseFormDialog<Partial<CustomOrderItem>>
      disclosure={disclosure}
      formMethods={formMethods}
      onSubmit={onSubmit}
      isLoading={false}
      header={'Custom Product Request'}
      footerRender={
        <FormControls
          isSubmitting={false}
          submitButtonText={'Add To Cart'}
          submitButtonProps={{ style: { width: '100%' } }}
        />
      }
      size={'md'}
      hideControls={true}>
      <CustomProductRequestForm formMethods={formMethods} />
    </BaseFormDialog>
  );
};
