import { BaseFields, FormComponents } from '@cksoftware/react-base';

export type sortByOption = 'low-to-high' | 'high-to-low' | 'name';

const sortByOptions = {
  options: [
    { Label: 'Name', Value: 'name' },
    { Label: 'Price: Low to High', Value: 'low-to-high' },
    { Label: 'Price: High to Low', Value: 'high-to-low' }
  ]
};

type SortBySelectProps = {
  onSelectSortBy: (value: sortByOption | undefined) => void;
  value: string | undefined;
};

export const SortBySelect = (props: SortBySelectProps) => (
  <FormComponents.FormFieldContainer invalid={false}>
    <BaseFields.SelectInput
      value={props.value}
      aria-label='Sort by'
      options={sortByOptions.options}
      onChangeControlled={(val) => props.onSelectSortBy(val as sortByOption)}></BaseFields.SelectInput>
  </FormComponents.FormFieldContainer>
);
