import { Box, Heading, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ProductFilterState, SortingState, useProductListing } from '../hooks/useProductListing.tsx';
import { sortByOption, SortBySelect } from './SortBySelect.tsx';
import { ProductList } from './ProductList.tsx';
import { ProductViewModal } from '../productView/ProductViewModal.tsx';
import { BaseTablePager, BaseTablePagingState } from '@cksoftware/react-base';

type Props = {
  filterState: ProductFilterState;
  setFormIds: (value: number[]) => void;
};

export const ProductCatalogue = (props: Props) => {
  const [sortingState, setSortingState] = useState<SortingState>({});
  const [pagingState, setPagingState] = useState<BaseTablePagingState>({
    currentPageIndex: 0,
    pageSize: 50
  });

  const [sortValue, setSortValue] = useState<string | undefined>('name');

  const { data } = useProductListing({
    pagingState: pagingState,
    sortingState: sortingState,
    filterState: props.filterState
  });

  const sortingStateMapper: (sortBy: sortByOption | undefined, previousSortingState: SortingState) => SortingState =
    useCallback((sortBy: sortByOption | undefined, previousSortingState: SortingState) => {
      if (sortBy === 'low-to-high') return { ...previousSortingState, orderBy: 'packSizePrice', isDesc: false };
      else if (sortBy === 'high-to-low') return { ...previousSortingState, orderBy: 'packSizePrice', isDesc: true };
      else if (sortBy === 'name') return { ...previousSortingState, orderBy: 'name', isDesc: false };
      return { ...previousSortingState, orderBy: undefined, isDesc: undefined };
    }, []);

  const disclosure = useDisclosure();
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const handleProductViewOpen = useCallback(
    (productId: number) => {
      setSelectedProductId(productId);
      disclosure.onOpen();
    },
    [disclosure.onOpen]
  );

  return (
    <Box maxW={'1400px'} mx='auto' px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }}>
      <Box width='full'>
        <Stack
          gap={{ base: '6', md: '4' }}
          direction={{ base: 'column', md: 'row' }}
          justify='space-between'
          align='flex-start'
          width='full'>
          <Stack direction={{ base: 'column', md: 'row' }} align='baseline'>
            <Heading size='md' fontSize='2xl'>
              Products
            </Heading>
            <Text color='gray.500'>{`(${data?.TotalRows ?? 0} products)`}</Text>
          </Stack>

          <HStack display={{ base: 'none', md: 'flex' }} minW={'250px'}>
            <Text flexShrink={0} color='gray.500' fontSize='sm'>
              Sort by
            </Text>
            <SortBySelect
              value={sortValue}
              onSelectSortBy={(newValue) => {
                setSortValue(newValue);
                setSortingState((prevFilter) => sortingStateMapper(newValue, prevFilter));
              }}
            />
          </HStack>
        </Stack>
        <Box mt='6' borderWidth='2px' minH='480px' rounded='xl' borderStyle='dashed'>
          <ProductList
            filterState={props.filterState}
            sortingState={sortingState}
            pagingState={pagingState}
            onProductViewOpen={handleProductViewOpen}
          />
          <Box marginLeft={'auto'} w={'fit-content'}>
            <BaseTablePager
              pagingState={{
                pagingState: pagingState,
                onPagingStateChange: setPagingState,
                totalItems: data?.TotalRows ?? 0
              }}
            />
          </Box>
        </Box>
      </Box>

      {selectedProductId && <ProductViewModal productId={selectedProductId} disclosure={disclosure} />}
    </Box>
  );
};
