import { BaseFields, FormComponents } from '@cksoftware/react-base';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  setSearchValue: (value: string) => void;
};

export const SearchInput = (props: Props) => {
  const debouncedState = useDebouncedCallback((value: string) => {
    props.setSearchValue(value);
  }, 500);

  return (
    <FormComponents.FormFieldContainer invalid={false}>
      <BaseFields.GenericInput
        onChange={(e) => {
          debouncedState(e.target.value);
        }}
        placeholder={'Product Search'}
        variant={'subtle'}
      />
    </FormComponents.FormFieldContainer>
  );
};
