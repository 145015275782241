import { UseFormReturn, useWatch } from 'react-hook-form';
import { createFieldHelper } from '@cksoftware/react-base';
import { useMemo } from 'react';
import { ProductListingModel } from '../models/ProductListingModel.ts';
import { useProductViewSelectOptions } from './useProductViewSelectOptions.ts';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';
import { DeliveryType, PatientType } from '../../checkout/types.ts';
import { masks } from '../../../../constants/masks.ts';
import { Box } from '@chakra-ui/react';
import { OrderItem } from '../../itemTypes.ts';

export const useProductViewFormFields = (
  formMethods: UseFormReturn<Partial<OrderItem>>,
  product: ProductListingModel | undefined,
  productOptionId: number | undefined
) => {
  const fieldHelper = createFieldHelper<Partial<OrderItem>>(formMethods);

  const matchingProductOption = useMemo(() => {
    if (!product?.ProductOptions) {
      return undefined;
    }
    return product?.ProductOptions.find((val) => val.FormId === productOptionId);
  }, [product?.ProductOptions, productOptionId]);

  const { formSelect, flavourSelect, strengthSelect, packSelect } = useProductViewSelectOptions(
    product,
    matchingProductOption
  );

  const patientType = useWatch({
    control: formMethods.control,
    name: 'PatientType'
  });

  const deliveryType = useWatch({
    control: formMethods.control,
    name: 'DeliveryType'
  });

  return useMemo(() => {
    return [
      fieldHelper
        .selectInput('ProductOptionId', { items: formSelect })
        .withLabel('Product Form')
        .withValidation({ required: {} }),

      fieldHelper
        .selectInput('FlavorId', { items: flavourSelect })
        .withLabel('Flavor')
        .withValidation({ required: {} })
        .withRenderIf(() => {
          return matchingProductOption?.HasFlavours ?? false;
        })
        .withInputProps({
          readOnly: !productOptionId
        }),

      fieldHelper
        .selectInput('ProductStrengthId', { items: strengthSelect })
        .withLabel('Strength')
        .withValidation({ required: {} })
        .withInputProps({
          readOnly: !productOptionId
        }),

      fieldHelper
        .selectInput('ProductPackId', { items: packSelect })
        .withLabel('Pack')
        .withValidation({ required: {} })
        .withInputProps({
          readOnly: !productOptionId,
          disableSort: true
        }),
      fieldHelper.numericInput('Refills').withValidation({ required: {} }),
      fieldHelper.textArea('InstructionsForUse').withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('AuthorizedUserId', { searchUrl: ControllerRoutes.Util.selectOption.authorizedVets })
        .withLabel('Authorized Vet')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('PatientType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('PatientType')
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('PetName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerPhoneNumber')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withInputMask(masks.phone)
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('DeliveryType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('DeliveryType')
        })
        .withRenderUnder(
          deliveryType == DeliveryType.DeliverToClinic ? (
            <Box fontSize={'xs'} marginTop={'-8px'} fontStyle={'italic'}>
              Delivery will be sent to address on file
            </Box>
          ) : (
            <></>
          )
        )
        .withValidation({ required: {} }),
      fieldHelper
        .addressInput('DeliveryAddress.AddressDisplay', {
          countryField: 'DeliveryAddress.Country',
          postalCodeField: 'DeliveryAddress.PostalCode',
          streetField: 'DeliveryAddress.AddressLineOne',
          cityField: 'DeliveryAddress.City',
          provinceField: 'DeliveryAddress.Province'
        })
        .withLabel('Patient Address')
        .withRenderIf(() => {
          return deliveryType == DeliveryType.DeliverToPatient;
        }),
      fieldHelper.textArea('DeliveryComments'),

      fieldHelper.textArea('ItemComment').withLabel('Order Note')
    ];
  }, [
    fieldHelper,
    formSelect,
    flavourSelect,
    productOptionId,
    matchingProductOption,
    matchingProductOption?.HasFlavours
  ]);
};
