import { useCustomRequestStateStore } from './useCustomRequestStore.tsx';
import { useMemo } from 'react';
import { useShoppingCartItemStore } from '../shoppingCart/hooks/useShoppingCartStateStore.tsx';

export function useShoppingCartStore() {
  const requestStore = useCustomRequestStateStore();
  const productStore = useShoppingCartItemStore();

  const totalItems = useMemo(() => {
    return requestStore.items.length + productStore.items.length;
  }, [requestStore.items, productStore.items]);

  return {
    items: productStore.items,
    customItems: requestStore.items,
    totalItems: totalItems,
    addProduct: productStore.addItem,
    addRequest: requestStore.addItem,
    clear: () => {
      productStore.clear();
      requestStore.clear();
    },
    setQuantity: (id: number, quantity: number) => {
      const product = productStore.items.find((x) => x.id === id);
      if (product) {
        productStore.updateItem(product.id, { ...product.data, Quantity: quantity });
      }

      const request = requestStore.items.find((x) => x.id === id);
      if (request) {
        requestStore.updateItem(request.id, { ...request.data, Quantity: quantity });
      }
    },
    removeItem: (id: number) => {
      productStore.removeItem(id);
      requestStore.removeItem(id);
    }
  };
}

export function useCartCount() {
  const requestStore = useCustomRequestStateStore();
  const productStore = useShoppingCartStore();

  return useMemo(() => {
    return requestStore.items.length + productStore.items.length;
  }, [requestStore.items, productStore.items]);
}
