import { Box, BoxProps, HStack, Image } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { BaseNav, RouteAuthenticationRequirement, useAuth, useNavItemStore } from '@cksoftware/react-base';
import { ApplicationRoutes } from '../../constants/appRoutes.ts';
import { useMemo } from 'react';
import logo from '../../assets/logo.png';

type NarrowLayoutProps = {
  containerProps?: BoxProps;
};

export const NarrowLayout = (props: NarrowLayoutProps) => {
  const routeNav = useNavItemStore((state) => {
    return { items: state.getDefault(), get: state.get };
  });

  const profileItems: BaseNav.NavItem = useMemo(() => {
    const subItems: BaseNav.NavItem[] = [
      routeNav.get(ApplicationRoutes.account.myProfile),
      routeNav.get(ApplicationRoutes.account.logout)
    ];
    return {
      text: 'Profile',
      subItems: subItems,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: []
    };
  }, []);
  const auth = useAuth();

  return (
    <Box as='section' width='100vw' height='100vh' overflowY='auto'>
      <BaseNav.Header
        avatarName={`${auth.user?.FirstName} ${auth.user?.LastName}`}
        navItems={routeNav.items}
        profileItems={profileItems}
        logo={
          <HStack>
            <Image height={['65px']} src={logo} alt='logo' />
          </HStack>
        }
      />
      <Box
        w={['100%', '100%', '100%', '90%']}
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}
        {...props.containerProps}>
        <Outlet />
      </Box>
    </Box>
  );
};
