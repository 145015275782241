import { AiOutlineSetting, AiOutlineUser } from 'react-icons/ai';
import { RiCapsuleLine, RiShoppingCartLine } from 'react-icons/ri';
import { ApplicationRoutes } from '../../../constants/appRoutes.ts';

export const items = {
  user: {
    label: 'Sign out',
    icon: <AiOutlineUser />,
    href: '#'
  },
  cart: {
    label: 'Cart',
    icon: <RiShoppingCartLine />,
    href: '#'
  },
  request: {
    label: 'Custom Product',
    icon: <RiCapsuleLine />,
    href: '#'
  },
  admin: {
    label: 'Admin',
    icon: <AiOutlineSetting />,
    href: ApplicationRoutes.ProductAdmin.manageProducts
  }
};
