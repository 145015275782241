import { CustomOrderItem, OrderItem } from '../itemTypes.ts';

export type CheckoutFormModel = {};

export type OrderRequestModel = CheckoutFormModel & {
  Products: OrderItem[];
  CustomRequests: CustomOrderItem[];
};

export enum PatientType {
  None = 0,
  OfficeUse = 1,
  Pet = 2
}

export enum DeliveryType {
  None = 0,
  DeliverToClinic = 1,
  DeliverToPatient = 3,
  Pickup = 4
}
