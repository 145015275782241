import { Center, Flex, HStack, Link, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type NavActionProps = {
  href?: string;
  label: string;
  icon: ReactNode;
  isActive?: boolean;
  children?: ReactNode;
  onClick?: () => void;
};

const MobileNavAction = (props: NavActionProps) => {
  const { label, isActive, href, children } = props;
  return (
    <Center onClick={props.onClick} height='56px' rounded='4' aria-current={isActive ? 'page' : undefined}>
      <Link href={href} _hover={{ bg: 'gray.100' }}>
        <Flex position='relative' direction='column' align='center' as='button'>
          <Text fontSize='sm' fontWeight='medium'>
            {label}
          </Text>
          {children}
        </Flex>
      </Link>
    </Center>
  );
};

export const DesktopNavAction = (props: NavActionProps) => {
  const { label, icon, href = '#' } = props;
  return (
    <HStack gap='2'>
      <Link as='a' onClick={props.onClick} href={href} textDecoration={'none'}>
        <Text fontSize='sm' fontWeight='semibold'>
          {label}
        </Text>
        {icon}
      </Link>
    </HStack>
  );
};

export const NavAction = {
  Mobile: MobileNavAction,
  Desktop: DesktopNavAction
};
