import { Box, Flex, HStack, Image } from '@chakra-ui/react';
import logo from '../../../assets/logo.png';

type Props = {
  setSearchValue: (value: string) => void;
  onCartClick: () => void;
};

export const CustomerNavMobile = (_: Props) => {
  return (
    <Box px='4' py='4' borderBottomWidth='1px' overflow='auto'>
      <Flex align='center' justify='space-between' mb='3' display={{ base: 'flex', lg: 'none' }}>
        <HStack gap='3'>
          <Box flexShrink={0}>
            <Image height={'8'} fill='currentColor' src={logo} alt='logo' />
          </Box>
        </HStack>
      </Flex>
      {/*<SearchInput setSearchValue={props.setSearchValue} />*/}
    </Box>
  );
};
