import { Box, SimpleGrid } from '@chakra-ui/react';
import { items } from './NavItemIcons';
import { CartCount } from './CartCount';
import { NavAction } from './NavAction';

type Props = {
  onCartClick: () => void;
  onRequestClick: () => void;
};

export const MobileBottomNav = (props: Props) => (
  <Box
    bg={'white'}
    pos='fixed'
    width='full'
    bottom='env(safe-area-inset-bottom)'
    borderTopWidth='1px'
    display={{ lg: 'none' }}>
    <SimpleGrid columns={3} padding='2'>
      <NavAction.Mobile {...items.cart} onClick={props.onCartClick} isActive>
        <CartCount right='-2' />
      </NavAction.Mobile>
      <NavAction.Mobile {...items.request} onClick={props.onRequestClick} />
      <NavAction.Mobile {...items.user} />
    </SimpleGrid>
  </Box>
);
