export type StoreItem<T> = {
  id: number;
  data: T;
};

export type ItemStore<T> = {
  items: StoreItem<T>[];
  addItem: (item: T) => number;
  removeItem: (id: number) => void;
  updateItem: (id: number, item: T) => void;
  clear: () => void;
};

export const itemStoreMethods = <T,>(set: any) => ({
  items: [] as StoreItem<T>[],
  addItem: (item: T) => {
    const id = Date.now();
    set((state: ItemStore<T>) => ({ items: [...state.items, { id, data: item }] }));
    return id;
  },
  clear: () => set(() => ({ items: [] })),
  removeItem: (id: number) => set((state: ItemStore<T>) => ({ items: state.items.filter((item) => item.id !== id) })),
  updateItem: (id: number, item: T) =>
    set((state: ItemStore<T>) => ({
      items: state.items.map((storeItem) => (storeItem.id === id ? { id: storeItem.id, data: item } : storeItem))
    }))
});
