import { formatCurrency, SelectOption } from '@cksoftware/react-base';
import { useMemo } from 'react';
import { ProductListingModel, ProductOptionModel } from '../models/ProductListingModel.ts';

export function useProductViewSelectOptions(
  product: ProductListingModel | undefined,
  productOption: ProductOptionModel | undefined
) {
  const formSelect: SelectOption[] = useMemo(() => {
    if (!product?.ProductOptions) {
      return [];
    }
    return product?.ProductOptions.map((val) => {
      return { Label: `${val.Form}`, Value: val.FormId };
    });
  }, [product?.ProductOptions]);

  const flavourSelect: SelectOption[] = useMemo(() => {
    if (!productOption) {
      return [];
    }
    return productOption.Flavors;
  }, [productOption]);

  const strengthSelect: SelectOption[] = useMemo(() => {
    if (!productOption) {
      return [];
    }

    const toReturn: SelectOption[] = [];
    productOption.ProductPacks.forEach((val) => {
      if (toReturn.some((v) => v.Value === val.ProductStrengthId) || val.Price == undefined || val.Price == 0) {
        return;
      }
      toReturn.push({ Label: `${val.Strength} ${val.StrengthUnit}`, Value: val.ProductStrengthId });
    });
    return toReturn;
  }, [productOption, product?.ProductOptions]);

  const packSelect: SelectOption[] = useMemo(() => {
    if (!productOption) {
      return [];
    }

    const toReturn: SelectOption[] = [];
    productOption.ProductPacks.forEach((val) => {
      if (toReturn.some((v) => v.Value === val.ProductPackId) || val.Price == undefined || val.Price == 0) {
        return;
      }
      toReturn.push({ Label: `${val.Quantity} @ ${formatCurrency(val.Price, 2, 2)}`, Value: val.ProductPackId });
    });
    return toReturn;
  }, [productOption, product?.ProductOptions]);

  return { formSelect, flavourSelect, strengthSelect, packSelect };
}
