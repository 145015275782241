import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { formatCurrency } from '@cksoftware/react-base';

interface PriceTagProps {
  minimumPrice: number;
  maximumPrice?: number;
  rootProps?: StackProps;
  priceProps?: TextProps;
  isRequest?: boolean;
}

export function formatPrice(value: number) {
  return formatCurrency(value, 2, 2);
}

export const PriceTag = (props: PriceTagProps) => {
  const { minimumPrice, maximumPrice, rootProps, priceProps, isRequest } = props;
  return (
    <HStack gap='1' {...rootProps}>
      <Price textProps={priceProps}>
        {isRequest
          ? 'Requested'
          : formatPrice(minimumPrice) +
            (maximumPrice && minimumPrice !== maximumPrice ? ' - ' + formatPrice(maximumPrice) : '')}
      </Price>
    </HStack>
  );
};

interface PriceProps {
  children?: ReactNode;
  isOnSale?: boolean;
  textProps?: TextProps;
}

const Price = (props: PriceProps) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = 'gray.700';
  const onSaleColor = 'gray.400';
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text
      as='span'
      fontWeight='medium'
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}
      {...textProps}>
      {children}
    </Text>
  );
};
