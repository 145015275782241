import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useFilterState } from './hooks/useFilterState.tsx';
import { ProductCatalogue } from './components/ProductCatalogue.tsx';
import { CustomProductRequestModal } from './customProductRequest/CustomProductRequest.tsx';
import { CustomerNavDesktop } from './components/CustomerNavDesktop.tsx';
import { ShoppingCart } from './shoppingCart/ShoppingCart.tsx';
import { CheckoutModal } from './checkout/checkout.tsx';
import { CustomerNavMobile } from './components/CustomerNavMobile.tsx';
import { MobileBottomNav } from './components/MobileBottomNav.tsx';

export const ProductListing = () => {
  const { filterState, setKeyword, setFormIds } = useFilterState();

  const customRequestDisclosure = useDisclosure();
  const cartDisclosure = useDisclosure();
  const checkoutDisclosure = useDisclosure();

  return (
    <Box bg={'white'}>
      <Flex direction='column' overflow='hidden' display={{ base: 'flex', lg: 'none' }}>
        <CustomerNavMobile setSearchValue={setKeyword} onCartClick={cartDisclosure.onOpen} />
        <Flex flex='1' fontSize='sm' overflow='auto'>
          <Box p='5' width='full' height='100%' overflowY='auto'>
            <ProductCatalogue filterState={filterState} setFormIds={setFormIds} />
          </Box>
        </Flex>
        <MobileBottomNav onCartClick={cartDisclosure.onOpen} onRequestClick={customRequestDisclosure.onOpen} />
      </Flex>
      <Box display={{ base: 'none', lg: 'block' }}>
        <CustomerNavDesktop
          setSearchValue={setKeyword}
          onRequestClick={customRequestDisclosure.onOpen}
          onCartClick={cartDisclosure.onOpen}
        />
        <Box borderTopWidth='1px' borderColor={'gray.200'} bg={'white'}>
          <ProductCatalogue filterState={filterState} setFormIds={setFormIds} />
          {customRequestDisclosure.open && <CustomProductRequestModal disclosure={customRequestDisclosure} />}
        </Box>
      </Box>

      <ShoppingCart onCheckout={checkoutDisclosure.onOpen} disclosure={cartDisclosure} />
      <CheckoutModal disclosure={checkoutDisclosure} />
    </Box>
  );
};
