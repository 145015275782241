import { Box } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppToaster, useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { ErrorContainer } from '../ErrorContainer';
import { ElementUser } from './globalModels/user/elementUser';
import { useNavWithRouting } from './routes/AppRouter';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraRootProvider } from './components/ui/ChakraRootProvider.tsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  }
});

export const App = () => {
  const { initUser, hasLoaded } = useAuth<ElementUser>();
  const AppRouter = createBrowserRouter(useNavWithRouting());

  useEffect(() => {
    async function initAuth() {
      await initUser();
    }

    initAuth();
  }, []);

  return (
    <ChakraRootProvider>
      <AppToaster />
      <Box maxHeight={'100vh'}>
        <QueryClientProvider client={queryClient}>
          <ErrorContainer>{hasLoaded && <RouterProvider router={AppRouter} />}</ErrorContainer>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Box>
    </ChakraRootProvider>
  );
};
