import { Center, Flex, Text, useControllableState, UseControllableStateProps } from '@chakra-ui/react';
import { BaseButtonProps } from '@cksoftware/react-base/dist/BaseComponents/Buttons/BaseButton';
import { BaseButton } from '@cksoftware/react-base';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

interface QuantityPickerProps extends UseControllableStateProps<number> {
  max?: number;
  min?: number;
  rootProps?: any;
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const { min = 0, max, ...rest } = props;

  const [value, setValue] = useControllableState(rest);
  const handleDecrement = () => setValue(value === min ? value : value - 1);
  const handleIncrement = () => setValue(value === max ? value : value + 1);
  return (
    <Flex borderRadius='base' borderWidth='1px' justifyContent='space-between'>
      <QuantityPickerButton
        onClick={handleDecrement}
        icon={faMinus}
        disabled={value === min ? ['Min quantity reached'] : undefined}
        aria-label='Decrement'
      />
      <Center minW='8'>
        <Text as='span' fontWeight='medium' userSelect='none'>
          {value}
        </Text>
      </Center>
      <QuantityPickerButton
        onClick={handleIncrement}
        icon={faPlus}
        disabled={value === max ? ['Max quantity reached'] : undefined}
        aria-label='Increment'
      />
    </Flex>
  );
};

const QuantityPickerButton = (props: BaseButtonProps) => (
  <BaseButton
    size='sm'
    fontSize='md'
    iconProps={{
      color: 'gray.300'
    }}
    variant='ghost'
    _focus={{ boxShadow: 'none' }}
    _focusVisible={{ boxShadow: 'outline' }}
    {...props}
  />
);
